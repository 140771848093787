import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import {
  contentfulLongTextPropType,
  gatsbyImageDataPropType,
} from "~/propTypes";

function Seo({
  data: {
    title,
    description: {
      internal: { content: description },
    },
    image,
  },
}) {
  const imageUrl = image?.gatsbyImageData?.images?.fallback?.src;
  return (
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: "website",
        },
        {
          property: `og:image`,
          content: imageUrl,
        },
        // {
        //   property: `og:url`,
        //   content: ogUrl,
        // },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
        {
          name: `twitter:image`,
          content: imageUrl,
        },
        // {
        //   name: `twitter:url`,
        //   content: twitterUrl,
        // },
        // {
        //   name: "robots",
        //   content: noIndex === true ? "noindex" : "index",
        // },
      ]}
    >
      {/*{canonicalUrl && <link rel="canonical" href={canonicalUrl} />}*/}
    </Helmet>
  );
}

Seo.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: contentfulLongTextPropType.isRequired,
    image: PropTypes.shape({
      gatsbyImageData: gatsbyImageDataPropType.isRequired,
    }),
  }).isRequired,
};

const dataFragment = graphql`
  fragment Seo_data on ContentfulPageMetadata {
    title
    description {
      internal {
        content
      }
    }
    image {
      gatsbyImageData(
        quality: 100
        width: 1200
        height: 627
        resizingBehavior: CROP
      )
    }
  }
`;

export { dataFragment };
export default Seo;
