import React from "react";
import Page from "~/templates/Page";

const data = {
  page: {
    metadata: {
      title: "Design",
      description: {
        internal: {
          content:
            "Design leads the integrated Blink DMI™ process. Centrally coordinated, the process ensures that as-built is as-designed with the digital twin driving manufacture and integration.",
        },
      },
      image: null,
    },
    sections: [
      {
        __typename: "ContentfulTextAndCardsSection",
        title: "Design",
        displayType: "white-rows",
        numberOfColumns: 2,
        headingLogo: {
          file: {
            url: "//images.ctfassets.net/thdo0maiis3p/4ZvocliOI7aUJOSzCeyusW/89c8340e991b08b2432f29fb4a3c51af/Design.svg",
          },
        },
        summary: {
          raw: '{"nodeType":"document","data":{},"content":[{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"Design leads the integrated Blink DMI","marks":[],"data":{}},{"nodeType":"text","value":"®","marks":[],"data":{}},{"nodeType":"text","value":" process. Centrally coordinated, the process ensures that as-built is as-designed with the digital twin driving manufacture and integration.","marks":[],"data":{}}]},{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"This is unlike traditional building methods, where construction often commences before the design is fully resolved and complete. This can lead to design and program changes throughout the construction phase.","marks":[],"data":{}}]}]}',
        },
        buttonText: null,
        buttonLink: null,
        cards: [
          {
            id: "c92e2a7f-a71c-5591-b3d5-92363cafc797",
            title: {
              internal: {
                content: "AI-assisted concept design overlay",
              },
            },
            image: {
              title: "Concept design",
              file: {
                url: "//images.ctfassets.net/thdo0maiis3p/2qTVJqOeCbknIehpHcPEq4/dacd9e6e54145f13f5dd5025590470a9/concept-design.jpg",
              },
              gatsbyImageData: {
                images: {
                  sources: [
                    {
                      srcSet:
                        "https://images.ctfassets.net/thdo0maiis3p/2qTVJqOeCbknIehpHcPEq4/dacd9e6e54145f13f5dd5025590470a9/concept-design.jpg?w=750&h=500&q=100&fm=webp&bg=white 750w,\nhttps://images.ctfassets.net/thdo0maiis3p/2qTVJqOeCbknIehpHcPEq4/dacd9e6e54145f13f5dd5025590470a9/concept-design.jpg?w=918&h=612&q=100&fm=webp&bg=white 918w",
                      sizes: "100vw",
                      type: "image/webp",
                    },
                  ],
                  fallback: {
                    src: "https://images.ctfassets.net/thdo0maiis3p/2qTVJqOeCbknIehpHcPEq4/dacd9e6e54145f13f5dd5025590470a9/concept-design.jpg?w=918&h=612&fl=progressive&q=100&fm=jpg&bg=white",
                    srcSet:
                      "https://images.ctfassets.net/thdo0maiis3p/2qTVJqOeCbknIehpHcPEq4/dacd9e6e54145f13f5dd5025590470a9/concept-design.jpg?w=750&h=500&fl=progressive&q=100&fm=jpg&bg=white 750w,\nhttps://images.ctfassets.net/thdo0maiis3p/2qTVJqOeCbknIehpHcPEq4/dacd9e6e54145f13f5dd5025590470a9/concept-design.jpg?w=918&h=612&fl=progressive&q=100&fm=jpg&bg=white 918w",
                    sizes: "100vw",
                  },
                },
                layout: "fullWidth",
                backgroundColor: "white",
                width: 1,
                height: 0.6666666666666666,
              },
            },
            content: {
              raw: '{"nodeType":"document","data":{},"content":[{"nodeType":"unordered-list","data":{},"content":[{"nodeType":"list-item","data":{},"content":[{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"Overlay PT Blink geometry on any architecture, placing the backbone trays, blades and columns.","marks":[],"data":{}}]}]},{"nodeType":"list-item","data":{},"content":[{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"Conduct detailed structural analysis using high-powered design software.","marks":[],"data":{}}]}]},{"nodeType":"list-item","data":{},"content":[{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"Automated design takes care of the grunt work to allow architects and engineers to focus on creativity.","marks":[],"data":{}}]}]},{"nodeType":"list-item","data":{},"content":[{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"Identify manufacturers of structural and non-structural components and use their performance designs.","marks":[],"data":{}}]}]}]},{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"","marks":[],"data":{}}]}]}',
            },
            ctaLink: null,
            ctaText: null,
          },
          {
            id: "e02eb04e-ff7e-502d-95a9-107af2be67fa",
            title: {
              internal: {
                content: "Detailed design in hours not days",
              },
            },
            image: {
              title: "Modular bathroom",
              file: {
                url: "//images.ctfassets.net/thdo0maiis3p/1RQpO4SCstYALR4bhnlspD/badf599832863fcfd347bc972fe8c05c/modular-bathroom.jpg",
              },
              gatsbyImageData: {
                images: {
                  sources: [
                    {
                      srcSet:
                        "https://images.ctfassets.net/thdo0maiis3p/1RQpO4SCstYALR4bhnlspD/badf599832863fcfd347bc972fe8c05c/modular-bathroom.jpg?w=750&h=500&q=100&fm=webp&bg=white 750w,\nhttps://images.ctfassets.net/thdo0maiis3p/1RQpO4SCstYALR4bhnlspD/badf599832863fcfd347bc972fe8c05c/modular-bathroom.jpg?w=952&h=635&q=100&fm=webp&bg=white 952w",
                      sizes: "100vw",
                      type: "image/webp",
                    },
                  ],
                  fallback: {
                    src: "https://images.ctfassets.net/thdo0maiis3p/1RQpO4SCstYALR4bhnlspD/badf599832863fcfd347bc972fe8c05c/modular-bathroom.jpg?w=952&h=635&fl=progressive&q=100&fm=jpg&bg=white",
                    srcSet:
                      "https://images.ctfassets.net/thdo0maiis3p/1RQpO4SCstYALR4bhnlspD/badf599832863fcfd347bc972fe8c05c/modular-bathroom.jpg?w=750&h=500&fl=progressive&q=100&fm=jpg&bg=white 750w,\nhttps://images.ctfassets.net/thdo0maiis3p/1RQpO4SCstYALR4bhnlspD/badf599832863fcfd347bc972fe8c05c/modular-bathroom.jpg?w=952&h=635&fl=progressive&q=100&fm=jpg&bg=white 952w",
                    sizes: "100vw",
                  },
                },
                layout: "fullWidth",
                backgroundColor: "white",
                width: 1,
                height: 0.667016806722689,
              },
            },
            content: {
              raw: '{"nodeType":"document","data":{},"content":[{"nodeType":"unordered-list","data":{},"content":[{"nodeType":"list-item","data":{},"content":[{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"Use the menu interface to detail the whole backbone project in hours not days.","marks":[],"data":{}}]}]},{"nodeType":"list-item","data":{},"content":[{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"Add all non-structural components such as stairs, bathrooms, kitchens, façades, air conditioning, walls, ceilings and windows.","marks":[],"data":{}}]}]},{"nodeType":"list-item","data":{},"content":[{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"Receive component manufacturers’ 3D models, time schedules, costs and warrantees.","marks":[],"data":{}}]}]},{"nodeType":"list-item","data":{},"content":[{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"Commence code compliance.","marks":[],"data":{}}]}]}]},{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"","marks":[],"data":{}}]}]}',
            },
            ctaLink: null,
            ctaText: null,
          },
          {
            id: "78d9c855-1460-5917-a0b3-043f97f01df1",
            title: {
              internal: {
                content: "Fully compliant design for manufacture",
              },
            },
            image: {
              title: "Fully compliant design for manufacture",
              file: {
                url: "//images.ctfassets.net/thdo0maiis3p/1t4O6JhvG6kFoqzPxJPJno/5a083c460313d668301fdcdaa24c64cc/compliant-design.jpg",
              },
              gatsbyImageData: {
                images: {
                  sources: [
                    {
                      srcSet:
                        "https://images.ctfassets.net/thdo0maiis3p/1t4O6JhvG6kFoqzPxJPJno/5a083c460313d668301fdcdaa24c64cc/compliant-design.jpg?w=665&h=443&q=100&fm=webp&bg=white 665w",
                      sizes: "100vw",
                      type: "image/webp",
                    },
                  ],
                  fallback: {
                    src: "https://images.ctfassets.net/thdo0maiis3p/1t4O6JhvG6kFoqzPxJPJno/5a083c460313d668301fdcdaa24c64cc/compliant-design.jpg?w=665&h=443&fl=progressive&q=100&fm=jpg&bg=white",
                    srcSet:
                      "https://images.ctfassets.net/thdo0maiis3p/1t4O6JhvG6kFoqzPxJPJno/5a083c460313d668301fdcdaa24c64cc/compliant-design.jpg?w=665&h=443&fl=progressive&q=100&fm=jpg&bg=white 665w",
                    sizes: "100vw",
                  },
                },
                layout: "fullWidth",
                backgroundColor: "white",
                width: 1,
                height: 0.6661654135338345,
              },
            },
            content: {
              raw: '{"data":{},"content":[{"data":{},"content":[{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"Add site works and trades","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"list-item"},{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"Test non-structural components for fit and produce the final backbone ready for manufacture","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"list-item"},{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"Achieve a fully compliant design to Australian and international standards and building codes","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"list-item"},{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"Digital twin is ready","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"list-item"}],"nodeType":"unordered-list"},{"data":{},"content":[{"data":{},"marks":[],"value":"","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"document"}',
            },
            ctaLink: null,
            ctaText: null,
          },
        ],
        id: "4f380052-f6a9-5028-aed7-e81b2a01a973",
      },
      {
        __typename: "ContentfulTextPointsSection",
        heading: "Benefits",
        subHeading: "The benefits of a design-led process:",
        image: {
          gatsbyImageData: {
            images: {
              sources: [
                {
                  srcSet:
                    "https://images.ctfassets.net/thdo0maiis3p/7uuzyvxf4Ac3sqoGxB6ZNp/d03cca9d1ab54d4d115783f75be1558d/Benefits.jpg?w=580&h=688&q=100&fm=webp&bg=white 580w",
                  sizes: "100vw",
                  type: "image/webp",
                },
              ],
              fallback: {
                src: "https://images.ctfassets.net/thdo0maiis3p/7uuzyvxf4Ac3sqoGxB6ZNp/d03cca9d1ab54d4d115783f75be1558d/Benefits.jpg?w=580&h=688&fl=progressive&q=100&fm=jpg&bg=white",
                srcSet:
                  "https://images.ctfassets.net/thdo0maiis3p/7uuzyvxf4Ac3sqoGxB6ZNp/d03cca9d1ab54d4d115783f75be1558d/Benefits.jpg?w=580&h=688&fl=progressive&q=100&fm=jpg&bg=white 580w",
                sizes: "100vw",
              },
            },
            layout: "fullWidth",
            backgroundColor: "white",
            width: 1,
            height: 1.186206896551724,
          },
        },
        textPoints: [
          {
            id: "55188c59-42cf-5fe3-b55e-cd4616b91024",
            heading: "Safety through design",
            content: {
              raw: '{"nodeType":"document","data":{},"content":[{"nodeType":"paragraph","content":[{"nodeType":"text","value":"The Blink DMI™ method has safety built in from the Design stage. All work methodology is modelled and designed around safety. Our ecosystem partners are integrally involved in the design and implementation of safety. For each project, a safety design manual is developed the licensed engineer and signed off by all parties.","marks":[],"data":{}}],"data":{}}]}',
            },
            icon: {
              file: {
                url: "//images.ctfassets.net/thdo0maiis3p/5zSmz3b52VPEsZpsWx3r2s/52dfbeb3d0b16c1197b710f1c13357f8/Safety-Through-Design.svg",
              },
            },
          },
          {
            id: "fdcc10ac-04fa-57ff-8843-2f07c04af161",
            heading: "Early code compliance",
            content: {
              raw: '{"nodeType":"document","data":{},"content":[{"nodeType":"paragraph","content":[{"nodeType":"text","value":"With Blink DMI™,  as-designed is as-built. PT Blink solutions comply with relevant local and international codes throughout the world. For each project a code compliance document is produced for the appropriate jurisdiction and is signed off by architects, engineers and other consultants prior to manufacture.","marks":[],"data":{}}],"data":{}}]}',
            },
            icon: {
              file: {
                url: "//images.ctfassets.net/thdo0maiis3p/6mxtoJy7GxaRHMnOj6D4FI/1c8a360e4e963075c66d7b2de5cbf97c/Code-Compliance.svg",
              },
            },
          },
          {
            id: "da1c413b-2e7d-5ea0-b239-6f139e1634e9",
            heading: "Materials handling by design",
            content: {
              raw: '{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"Tag and trace technology from design through to procurement, manufacture, shipping and delivery allows real-time inventory control and just in time delivery.","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"document"}',
            },
            icon: {
              file: {
                url: "//images.ctfassets.net/thdo0maiis3p/7HAnMZNpskq3VMdmv8FT1/47f4a449aee49ffd1cd1f161690b2fe8/Materials-Handling.svg",
              },
            },
          },
          {
            id: "ef758a68-f357-5001-a364-d903590f5c86",
            heading: "Building services designed for manufacture",
            content: {
              raw: '{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"Building services are designed into componentry for manufacture. Integrated building services complete with fire collars minimise site-based trade interface and increase tolerances, build quality & safety. Digital coordination allows clash resolution in the factory prior to manufacture and shipping.","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"document"}',
            },
            icon: {
              file: {
                url: "//images.ctfassets.net/thdo0maiis3p/2fEsSglSQMf6DusYMTjSRJ/6e921faafea0f12018941bbef75affc8/Building-Services.svg",
              },
            },
          },
        ],
        id: "934e64c0-9cf3-57b5-bd53-b0c7b97863f2",
      },
      {
        __typename: "ContentfulTextAndCardsSection",
        title: "Design - Manufacture - Integrate",
        displayType: "white",
        numberOfColumns: 2,
        headingLogo: {
          file: {
            url: "//images.ctfassets.net/thdo0maiis3p/6ziQq181drzbTW2PlV9Vzu/e4c7cd1333e86b16a8c6f4478c9ea167/Blink-DMI.svg",
          },
        },
        summary: null,
        buttonText: null,
        buttonLink: null,
        cards: [
          {
            id: "dfa77381-3cfc-573f-8756-59dddf4ed84e",
            title: {
              internal: {
                content: "Manufacture",
              },
            },
            image: {
              title: "Manufacture",
              file: {
                url: "//images.ctfassets.net/thdo0maiis3p/2XA6cJgh5nArzbW2gmXttD/aa714eee82c94ec3f98d2a5efd9a6565/Manufacture.svg",
              },
              gatsbyImageData: null,
            },
            content: {
              raw: '{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"Concurrent manufacture of the structure and components slashes project time while reducing waste and injury.","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"document"}',
            },
            ctaLink: "/manufacture",
            ctaText: "Learn more",
          },
          {
            id: "3cda23b4-7b9e-53d6-b1ea-bc5f80c7c550",
            title: {
              internal: {
                content: "Integrate",
              },
            },
            image: {
              title: "Integrate",
              file: {
                url: "//images.ctfassets.net/thdo0maiis3p/3nlIYf1FfezR0vWf7E5F32/a63aca64e3657426d30eb29d2f00d067/Integrate.svg",
              },
              gatsbyImageData: null,
            },
            content: {
              raw: '{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"Removing the structure from the critical path, integration takes place earlier and much faster as services are built into components.","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"document"}',
            },
            ctaLink: "/integrate",
            ctaText: "Learn more",
          },
        ],
        id: "5083d4f0-1eee-5156-8157-affd6401af73",
      },
      {
        __typename: "ContentfulTextAndCardsSection",
        title: "Get Started",
        displayType: "blue",
        numberOfColumns: 2,
        headingLogo: null,
        summary: {
          raw: '{"nodeType":"document","data":{},"content":[{"nodeType":"paragraph","content":[{"nodeType":"text","value":"Let’s talk! Contact us with general enquiries or register your specific interest below.","marks":[],"data":{}}],"data":{}}]}',
        },
        buttonText: "Contact Us",
        buttonLink: "/contact/",
        cards: [
          {
            id: "99314b58-f0a4-5082-a598-4a304eb53733",
            title: {
              internal: {
                content: "FREE consultation -\nProperty Owners & Developers",
              },
            },
            image: null,
            content: {
              raw: '{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"Receive a free initial project assessment and understand the likely benefits of using Blink DMI®.","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"document"}',
            },
            ctaLink: "/book-consultation/",
            ctaText: "Book free consult",
          },
          {
            id: "13f6c73d-182e-58b1-83ad-3090fb55944b",
            title: {
              internal: {
                content: "Designers, Manufacturers and Integrators (builders)",
              },
            },
            image: null,
            content: {
              raw: '{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"Join to access projects and move up the value chain.​","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"document"}',
            },
            ctaLink: "/register/",
            ctaText: "Register to join",
          },
        ],
        id: "e2b5a91c-da91-57d0-b2ac-0967dc096306",
      },
    ],
  },
};

function Design() {
  return <Page data={data} />;
}

export default Design;
