import React, { useEffect, useMemo } from "react";
import { graphql } from "gatsby";
import { useLocation } from "@gatsbyjs/reach-router";
import PropTypes from "prop-types";
import Layout from "~/components/layout/Layout";
import PageSectionList from "~/components/PageSectionList";
import Seo from "~/components/Seo";

function Page({ data: { page } }) {
  const { hash } = useLocation();
  const sectionId = useMemo(() => {
    if (hash.length === 0) {
      return undefined;
    }

    const params = new URLSearchParams(hash.substr(1));
    return params.get("section");
  }, [hash]);
  useEffect(() => {
    if (!sectionId) {
      return;
    }

    const section = document.getElementById(sectionId)?.nextSibling;
    if (!section) {
      console.error(`No element with id ${sectionId}`);
      return;
    }

    window.scrollTo({
      top: section.offsetTop - 116,
      behavior: "smooth",
    });
  }, [sectionId]);
  return (
    <Layout>
      <Seo data={page.metadata} />
      {page.sections ? <PageSectionList data={page.sections} /> : null}
    </Layout>
  );
}

Page.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      metadata: Seo.propTypes.data,
      sections: PageSectionList.propTypes.data,
    }).isRequired,
  }).isRequired,
};

const query = graphql`
  query PageQuery($id: String!) {
    page: contentfulPage(id: { eq: $id }) {
      metadata {
        ...Seo_data
      }
      sections {
        ...PageSectionList_data
      }
    }
  }
`;

export { query };
export default Page;
